
	import iconfont from '../components/iconfont.vue';
	import {
		ElMessage
	} from 'element-plus';
	import { setCookie, getCookie } from '../util/index.js';
	export default {
		data() {
			return {
				isloading: false,
				viewmode: '2',
				statopt: 'all',
				brandopt: '0',
				groupopt: '0',
				keyword: '',
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				groups: [],
				brands: [],
				pdtlist: [],
				curpage: 1,
				pgcurpage: 0,
				total: 0,
				value: [],
				selectedrows: [],
				pagesize: 10,
			}
		},
		watch: {
			viewmode(val) {
				setCookie('productviewmode', val);
			},
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						// 打印clientWidth变化的值
						console.log(that.clientWidth)
						if (that.clientWidth < 992 && !that.isCollapse)
							that.isCollapse = true;
						if(that.clientWidth < 738)
							that.viewmode = '1';

						//that.curclass = (that.isCollapse ? "el-icon-s-unfold" : "el-icon-s-fold")
						//else if( that.clientWidth >= 992)
						//	that.isCollapse = false;
						that.timerw = false
					}, 400)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						// 打印clientHeight变化的值
						console.log(that.clientHeight)

						//else if( that.clientHeight >= 992)
						that.drawerMenuHeight = that.clientHeight;
						//	that.isCollapse = false;
						that.timerh = false
					}, 400)
				}
			},
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.ProductList;
		},
		methods: {
			onPageChanged(val) {
				this.curpage = val;
				this.Refresh();
				console.log(this.curpage + '-' + val);
			},
			DeleteSelected() {
				if(this.selectedrows.length <= 0) {
					ElMessage({
						message: this.PAGELANG.qxxzyscdwz,
						type: "warning"
					})
					return;
				}
				
				this.$confirm(this.PAGELANG.zzscxzdwz, {
					type: 'warning',
					callback: (act) => {
						console.log(act);
						if(act == 'confirm') {
							let delids = Array();
							for(var key in this.selectedrows) {
								delids.push(this.selectedrows[key].ID);
							}
							this.isloading = true;
							this.axios.post(this.actions.delpdtids, delids).then((response) => {
								//console.log(response.data);
								this.isloading = false;
								if (response.data.retinfo == "ok") {
									this.$message(this.LANG.system.caozuochenggong);
									this.$router.go(0);
								} else {
									this.$message(this.PAGELANG[response.data.retinfo]);
								}
							});
						}
					}
				});
			},
			handleSelChange(val) {
				this.selectedrows = val;
				console.log(this.selectedrows);
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			},
			RefreshBrands() {
				this.isloading = true;
				console.log(this.actions.getallbrand);
				this.axios.get(this.actions.getallbrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.isloading = false;
					this.brands = response.data;
					//console.log(this.groups);
					this.brands.unshift({
						ID: '0',
						Name: this.PAGELANG.sypp,
						Remark: this.PAGELANG.sypp,
						Status: true,
						ParentID: 0,
						RootID: 0,
					});
				});
			},
			RefreshGroups() {
				this.isloading = true;
				this.axios.get(this.actions.getallgroup, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.isloading = false;
					this.groups = response.data;
					//console.log(this.groups);
					this.groups.unshift({
						ID: '0',
						Name: this.PAGELANG.syfl,
						Remark: this.PAGELANG.syfl,
						Status: true,
						ParentID: 0,
						RootID: 0,
					});
				});
			},
			Refresh() {
				//console.log('Refresh');
				this.isloading = true;
				this.axios.get(this.actions.pdtlist, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						keyword: this.keyword,
						curpage: this.curpage,
						groupid: this.groupopt,
						pagesize: this.pagesize,
						status: this.statopt,
						brandid: this.brandopt,
						stamp: (new Date()).getTime()
					}
				}).then((response) => {
					console.log(response.data);
					this.isloading = false;
					if(response.data) {
						for(var iii=0;iii< response.data.list.length;++iii) {
							response.data.list[iii].PdtThumb = this.apibase + response.data.list[iii].PdtThumb;
						}
						this.pdtlist = response.data.list;
						this.total = parseInt(response.data.totalcount);
						this.curpage = parseInt(response.data.curpage);
						this.pgcurpage = this.curpage;
					}
				});
			},
			Switch(pdtid, status) {
				console.log("Switch - " + pdtid + ' - ' + status);
				if(!pdtid || !status)
					return;
				this.isloading = true;
				this.axios.get(this.actions.artstat, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pdtid: pdtid,
						stat: status
					}
				}).then((response) => {
					this.isloading = false;
					console.log(response.data);
					if(response.data.errinfo == 'ok') {
						console.log('ok')
					}
				});
			},
			Add() {
				console.log("Add");
				this.$router.push({
					path: '/goods/addproduct'
				});
			},
			Edit(pdtid) {
				console.log("Edit - " + pdtid);
				this.$router.push({
					path: '/goods/addproduct',
					query: {
						pdtid: pdtid,
						cpage: this.curpage
					}
				});
			},
			Delete(pdtid) {
				console.log("Delete - " + pdtid);
				this.$confirm(this.PAGELANG.scczbkhf, {
					type: 'warning',
					callback: (act) => {
						if(act == 'confirm') {
							this.isloading = true;
							this.axios.get(this.actions.delpdt, {
								headers: {
									"Content-Type": "application/json",
								},
								params: {
									id: pdtid,
								}
							}).then(() => {
								//console.log(response.data);
								this.isloading = false;
								this.Refresh();
							});
						}
					}
					});
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		mounted() {
			this.viewmode = getCookie('productviewmode');
			if(!this.viewmode)
				this.viewmode = '2';
				
			this.Refresh();
			this.RefreshBrands();
			this.RefreshGroups();
		},
		components: {
			iconfont
		}
	}
